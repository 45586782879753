import PropTypes from "prop-types";
import TitleSection from "Components/title_section";

const SectionWhatsOnFd = ({
	isMobile, youtubeMeta, buttonLink, margin,
}) => (
	<>
		<section className={["whatsonfd", isMobile ? "whatsonfd--mobile" : ""].join(" ")}>
			<div className={["whatsonfd__card-video", isMobile ? "whatsonfd__card-video--mobile" : ""].join(" ")}>
				<div className={["whatsonfd__video-wrapper", isMobile ? "whatsonfd__video-wrapper--mobile" : ""].join(" ")}>
					<div
						className={[
							"whatsonfd__video-container",
							isMobile
								? "whatsonfd__video-container--mobile"
								: "",
						].join(" ")}
					>
						<iframe
							width="100%"
							height="auto"
							src={`https://www.youtube.com/embed/${youtubeMeta?.id?.videoId}?rel=0&autoplay=1&mute=1`}
							title={youtubeMeta?.snippet?.title ?? ""}
							allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
							referrerPolicy="strict-origin-when-cross-origin"
							allowfullscreen
							style={{ border: "none", aspectRatio: "16/9 auto" }}
						/>
					</div>
				</div>
				<div className={["whatsonfd__section-text", isMobile ? "whatsonfd__section-text--mobile" : ""].join(" ")}>
					<div className={["whatsonfd__text", isMobile ? "whatsonfd__text--mobile" : ""].join(" ")}>
						<TitleSection
							title="What’s On FDN"
							color="#F0A3B6"
							isBackground
							isMobile={isMobile}
						/>
						<span className={["whatsonfd__description", isMobile ? "whatsonfd__description--mobile" : ""].join(" ")}>
							Stay connected with the latest updates from Female Daily
							Network. Don’t miss out, be part of our journey and let’s
							experience the excitement together!
						</span>
					</div>
					<a href={buttonLink} className={["whatsonfd__cta", isMobile ? "whatsonfd__cta--mobile" : ""].join(" ")}>Let’s Check It Out</a>
				</div>
			</div>
		</section>
		<style jsx>
			{`
				.whatsonfd {
					width: 100%;
					max-width: 1200px;
					margin: ${margin ?? "auto"};
					padding: var(--space-40);
					border-top: var(--space-12) solid var(--border-strong);
					border-right: var(--space-12) solid var(--border-strong);
					border-bottom: 2px solid var(--border-strong);
					border-left: 2px solid var(--border-strong);
					box-sizing: border-box;
				}

				.whatsonfd--mobile {
					border-top: var(--space-8) solid var(--border-strong);
					border-right: var(--space-8) solid var(--border-strong);
					padding: var(--space-20) var(--space-12);
					min-width: 288px;
					width: auto;
					margin: ${margin ?? "auto"};
				}

				.whatsonfd__card-video {
					display: flex;
					justify-content: center;
					align-items: flex-start;
					gap: var(--space-12);
				}

				.whatsonfd__card-video--mobile {
					flex-direction: column;
					gap: var(--space-20);
				}

				.whatsonfd__video-wrapper {
					position: relative;
					padding: var(--space-24);
					background-color: #FEF7F8;
					box-sizing: border-box;
					align-self: stretch;
					display: flex;
					justify-content: center;
					align-items: center;
				}
				
				.whatsonfd__video-wrapper--mobile {
					padding: 18px;
				}

				.whatsonfd__video-container {
					position: relative;
					width: auto;
					height: 314px;
					aspect-ratio: 16/9 auto;
				}

				.whatsonfd__video-container--mobile {
					height: 100%;
					min-height: 126px;
					max-height: 200px;
				}

				.whatsonfd__section-text {
					position: relative;
					z-index: 0;
					background-color: #FEF7F8;
					padding: var(--space-40) var(--space-24);
					display: flex;
					flex-direction: column;
					justify-content: center;
					gap: var(--space-12);
					align-self: stretch;
				}

				.whatsonfd__section-text--mobile {
					padding: var(--space-32) var(--space-24);
					gap: var(--space-24);
				}

				.whatsonfd__text {
					display: flex;
					flex-direction: column;
					gap: var(--space-12);
				}

				.whatsonfd__text--mobile {
					align-items: center;
				}

				.whatsonfd__description {
					margin: 0px;
					font-size: 18px;
					font-style: normal;
					font-weight: 400;
					line-height: 28px;
					font-family: 'Roboto', serif;
				}

				.whatsonfd__description--mobile {
					text-align: center;
					font-size: 14px;
					line-height: 24px; 
				}

				.whatsonfd__cta {
					font-family: Poppins;
					font-size: 18px;
					font-style: normal;
					font-weight: 400;
					line-height: 22px; 
					border: 1px solid #000;
					padding: var(--space-8);
					text-align: center;
					color: var(--text-default);
					width: 240px;
					padding: var(--space-12) var(--space-24);
					box-sizing: border-box;
					text-decoration: none;
					background-color: #fff;
				}

				.whatsonfd__cta--mobile {
					width: 100%;
				}
				
		`}
		</style>

		<style jsx global>
			{`
				.whatsonfd__section-text .title-text {
					margin-left: 0;
					font-size: 32px;
					padding: 0px var(--space-12);
				}

				.whatsonfd__section-text--mobile .title-text {
					margin-left: 0;
					font-size: 24px;
					padding: 0px;
				}

				.whatsonfd__section-text--mobile .title-section {
					margin-top: 0px;
				}
			`}
		</style>
	</>
);
SectionWhatsOnFd.propTypes = {
	isMobile: PropTypes.bool.isRequired,
	youtubeMeta: PropTypes.shape({
		id: PropTypes.shape({
			videoId: PropTypes.string.isRequired,
		}),
		snippet: PropTypes.shape({
			title: PropTypes.string.isRequired,
		}),
	}).isRequired,
	buttonLink: PropTypes.string.isRequired,
	margin: PropTypes.string,
};

SectionWhatsOnFd.defaultProps = {
	margin: "auto",
};

export default SectionWhatsOnFd;
